import React, { FunctionComponent } from "react";
import { LinkProps } from "./link.props";
import "./link.css";

const Link: FunctionComponent<LinkProps> = (props) => {
    return (
        <>
            <div className="link">
                <a href={props.url} target="_blank" rel="noopener noreferrer">{props.displayText}</a>
            </div>
        </>
    );
};

export default Link;
