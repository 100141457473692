import React, { FunctionComponent } from "react";
import { HeaderProps } from "./header.props";
import "./header.css";

const Header: FunctionComponent<HeaderProps> = (props) => {
    return (
        <>
            <div className="splash">
                <div className="main-header">
                    {props.mainHeader}
                </div>
                <div className="sub-header">
                    {props.subHeader}
                </div>
            </div>
        </>
    );
};

export default Header;
