import React, { FunctionComponent } from "react";
import "./linklist.css";
import ILink from "../../models/link";
import { Link } from "..";
import { LinklistProps } from "./linklist.props";

const Linklist: FunctionComponent<LinklistProps> = (props) => {
    return (
        <>
            <div className="links">
                {props.links.map((link: ILink) => <Link key={link.url} url={link.url} displayText={link.displayText} />)}
            </div>
        </>
    );
};

export default Linklist;
