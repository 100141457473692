import React from 'react';
import './App.css';
import { Header, LinkList } from "./components/"
import ILink from "./models/link";

function App() {
    const links: ILink[] = [
        {
            displayText: "YouTube",
            url: "https://www.youtube.com/channel/UCzTau23MpNCc7PzCWMFW2pA"
        },
        {
            displayText: "Twitter",
            url: "https://twitter.com/bourne_pro"
        },
        {
            displayText: "Instagram",
            url: "https://www.instagram.com/bourne.pro"
        },
        {
            displayText: "TikTok",
            url: "https://www.tiktok.com/@bourne.pro"
        },
        {
            displayText: "LinkedIn",
            url: "https://www.linkedin.com/in/-richard-bourne/"
        },
    ];

    return (
        <main className="container">
            <Header mainHeader="Richard Bourne" subHeader="Enthused by productivity, excited by technology" />

            <LinkList links={links} />
        </main>
    );
}

export default App;
